import {inject} from 'react-ioc'
import {type SurveyType} from 'posthog-js'
import {ctx} from 'new/ctx'
import {
  type AnalyticsEventArgs,
  type AnalyticsEventName,
} from 'types/AnalyticsEventName'
import {type AnalyticsGroupType} from 'types/AnalyticsGroupType'
import {type PosthogFeatureFlag} from 'types/FeatureFlag'
import {PosthogService} from './PosthogService'

////////////////////////////////////////////////////////////////////////////////

export class AnalyticsEventService {
  #posthogService = inject(this, PosthogService)
  #userUuid?: string

  #onEmitSubscribers: ((eventName: AnalyticsEventName) => void)[] = []

  constructor() {
    ctx.analyticsEventService = this
  }

  emit<T extends AnalyticsEventName>(
    eventName: T,
    ...args: AnalyticsEventArgs[T]
  ) {
    this.#posthogService.emit(eventName, ...args)
    requestAnimationFrame(() =>
      this.#onEmitSubscribers.forEach(cb => cb(eventName))
    )

    return this
  }

  onEmit(cb: (eventName: AnalyticsEventName) => void) {
    this.#onEmitSubscribers.push(cb)
    return this
  }

  optIn() {
    this.#posthogService.optIn()
  }

  optOut() {
    this.#posthogService.optOut()
  }

  fetchPosthogSurvey(surveyType: SurveyType, surveyId: string) {
    return this.#posthogService.posthogSurveys?.find(
      survey => survey.type === surveyType && survey.id === surveyId
    )
  }

  identifyUser(userUuid?: string) {
    this.#userUuid = userUuid
    return this
  }

  addUserProperties(properties: Record<string, any>) {
    if (!this.#userUuid) {
      return this
    }
    this.#posthogService.addUserProperties(this.#userUuid, properties)
    return this
  }

  isPosthogFeatureFlagEnabled(featureFlag: PosthogFeatureFlag) {
    return this.#posthogService.isFeatureEnabled(featureFlag)
  }

  addGroupProperties(
    groupType: AnalyticsGroupType,
    groupName: string,
    properties?: Record<string, any>
  ) {
    this.#posthogService.addGroupProperties(groupType, groupName, properties)
    return this
  }
}
